<template>
  <a-modal
    title="物流"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="物流公司" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            v-decorator="[
              'logistics_id',
              {
                rules: [
                  { required: true, message: '请选择' },
                ],
              },
            ]"
            @change="logisticsChange"
            allow-clear
            show-search
            :filter-option="false"
            :not-found-content="null"
            :default-active-first-option="false"
            @search="handleSearchLogistics"
            placeholder="物流公司">
            <a-select-option :key="key" :value="val.id" v-for="(val, key) in this.logisticsSearchList">{{ val.delivery_name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="单号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['waybill_no', {rules: [{required: true, message: '请输入'}]}]" placeholder="物流单号" allow-clear/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { common_logistics_list } from '@/api/common'
const fields = ['logistics_id', 'waybill_no']
export default {
  name: 'LogisticsForm',
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      logisticsList: [],
      logisticsSearchList: []
    }
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.loadEditInfo(this.model)
    // })
    this.loadLogisticsList()
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
    },
    loadLogisticsList () {
      common_logistics_list().then(res => {
        if (res.code === 1000) {
          this.logisticsList = res.data
          this.logisticsList.push({
            id: 0,
            delivery_id: 'OTHER',
            delivery_name: '其他'
          })
          this.logisticsSearchList = this.logisticsList
        }
      })
    },
    logisticsChange (value) {
      console.log(value)
      if (value === undefined) {
        this.logisticsSearchList = this.logisticsList
      }
    },
    handleSearchLogistics (value) {
      if (value !== undefined) {
        const unitsObj = []
        for (let i = 0; i < this.logisticsList.length; i++) {
          const name = this.logisticsList[i].delivery_name
          if (name.includes(value)) {
            unitsObj.push(this.logisticsList[i])
          }
        }
        this.logisticsSearchList = unitsObj
      } else {
        this.logisticsSearchList = this.logisticsList
      }
    }
  }
}
</script>
