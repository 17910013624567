var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"物流","width":680,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"物流公司","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'logistics_id',
            {
              rules: [
                { required: true, message: '请选择' },
              ],
            },
          ]),expression:"[\n            'logistics_id',\n            {\n              rules: [\n                { required: true, message: '请选择' },\n              ],\n            },\n          ]"}],attrs:{"allow-clear":"","show-search":"","filter-option":false,"not-found-content":null,"default-active-first-option":false,"placeholder":"物流公司"},on:{"change":_vm.logisticsChange,"search":_vm.handleSearchLogistics}},_vm._l((this.logisticsSearchList),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":val.id}},[_vm._v(_vm._s(val.delivery_name))])}),1)],1),_c('a-form-item',{attrs:{"label":"单号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['waybill_no', {rules: [{required: true, message: '请输入'}]}]),expression:"['waybill_no', {rules: [{required: true, message: '请输入'}]}]"}],attrs:{"placeholder":"物流单号","allow-clear":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }