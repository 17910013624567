<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号">
                <a-input v-model="queryParam.order_no" placeholder="请输入编号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="状态">
                <a-select v-model="queryParam.status" allow-clear placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.applet_order_status">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24" v-if="false">
              <a-form-item label="自提优先">
                <a-checkbox v-model="queryParam.distribution" @change="onDeliverFirstChange"/>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="handle_to_warehouse">转到仓库</a-button>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="handleExportOrder">导出订单</a-button>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" icon="import" @click="handleImport">导入物流单</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-alert :showIcon="false" style="margin-bottom: 16px" type="error">
        <template slot="message">
          <span style="margin-right: 12px">已选择: <a style="font-weight: 600">{{ this.selectedRowKeys.length }}</a></span>
          <a style="margin-left: 24px" @click="clear" :disabled="!hasSelected" v-if="hasSelected">清空</a>
        </template>
      </a-alert>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :row-selection="rowSelection"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :scroll="{ x: 1300 }"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="max-width: 150px; min-width: 110px;text-align: right">
          {{ (text/100).toFixed(2) }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleDetail(record)">详情</a>
            <a-divider type="vertical" v-if="showLogistics(record)"/>
            <a @click="handleLogistics(record)" v-if="showLogistics(record)">物流</a>
            <a-divider type="vertical" v-if="showMore(record)"/>
            <a-dropdown v-if="showMore(record)">
              <a class="ant-dropdown-link">
                更多 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="showLogistics(record) && false">
                  <a @click="handleLogisticsTrajectory(record)">物流轨迹</a>
                </a-menu-item>
                <a-menu-item v-if="showPushOrder(record)">
                  <a-popconfirm
                    title="订单推送给供应商吗？"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="handlePushOrder(record)"
                  >
                    <a>推送订单</a>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item v-if="showChangeStatus(record)">
                  <a @click="handleChangeStatus(record)">更改状态</a>
                </a-menu-item>
                <a-menu-item v-if="showChangeLogistics(record) && false">
                  <a @click="handleChangeLogistics(record)">{{ record.logistics ? '更改物流' : '添加物流' }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </span>
      </s-table>
      <a-modal
        v-model="select_status_visible"
        :confirm-loading="confirm_loading"
        title="审核"
        ok-text="确认"
        cancel-text="取消"
        @ok="select_status">
        <a-select style="width: 280px" @change="handleSelectChange" v-model="select_status_data">
          <a-select-option
            v-for=" (val, key) in this.$Dictionaries.applet_order_exam_status"
            :key="key"
            :value="key">
            {{ val }}
          </a-select-option>
        </a-select>
        <br />
        <br />
        <!--  <span v-if="select_status_data === '5'">-->
        <!--  退款金额：<a-input-number :min="0" v-model="refund" style="width: 180px"/> 元</span>-->
      </a-modal>
      <a-modal v-model="refund_status_visible" title="退款" ok-text="确认" cancel-text="取消" @ok="select_refund">
        <a-row :gutter="2">
          <a-col :md="12" :sm="24">
            <a-form-item label="金额(元)">
              <a-input-number :min="0" v-model="refund" style="width: 140px"/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="理由">
              <a-input v-model="refund_reason" placeholder="请输入理由"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-modal>
      <order-circulate-config
        ref="configModal"
        v-if="configVisible"
        :visible="configVisible"
        :loading="confirmLoading"
        :model="mdl"
        :title="title"
        @cancel="handleConfigCancel"
        @ok="handleConfigOk"
      />
      <order-logistics-import-form
        ref="orderLogisticsImportModal"
        v-if="import_form_visible"
        :visible.sync="import_form_visible"
        :loading="importConfirmLoading"
        @cancel="handleImportCancel"
        @ok="handleImportOk" />
      <order-logistics-trajectory-form
        ref="orderLogisticsTrajectoryModal"
        v-if="logisticsTrajectoryVisible"
        :visible.sync="logisticsTrajectoryVisible"
        :loading="loading"
        :model="mdl"
        @cancel="logisticsTrajectoryVisible=false"
      />
      <order-status-form
        ref="orderStatusModal"
        v-if="statusVisible"
        :visible.sync="statusVisible"
        :loading="statusConfirmLoading"
        :model="mdl"
        @cancel="handleStatusCancel"
        @ok="handleStatusOk" />
      <logistics-form
        ref="logisticsModal"
        v-if="logisticsVisible"
        :visible.sync="logisticsVisible"
        :loading="logisticsConfirmLoading"
        :model="mdl"
        @cancel="handleLogisticsCancel"
        @ok="handleLogisticsOk"
      />
      <order-logistics-list
        ref="logisticsListModal"
        v-if="logisticsListVisible"
        :visible="logisticsListVisible"
        :loading="logisticslistLoading"
        :model="mdl"
        @cancel="handleLogisticsListCancel"
        @ok="handleLogisticsListOK"
        @addLogistics="addLogistics"
      />
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import {
  doRefund,
  orderToExamine,
  orderList,
  putReceived,
  orderToWarehouse,
  putOrderStatus,
  putOrderToWarehouseConfig,
  orderToSupplier,
  wechatOrderExport,
  orderLogisticsImport, putOrderLogistics
} from '@/api/applet_shopmall_order'
import OrderCirculateConfig from '@/views/a-applet/shop_manage/OrderCirculateConfig'
import OrderLogisticsImportForm from '@/views/a-applet/shop_manage/logistics/OrderLogisticsImportForm'
import OrderLogisticsTrajectoryForm from '@/views/a-applet/shop_manage/logistics/OrderLogisticsTrajectoryForm'
import OrderStatusForm from '@/views/a-applet/shop_manage/OrderStatusForm'
import LogisticsForm from '@/views/a-applet/shop_manage/logistics/LogisticsForm'
import OrderLogisticsList from '@/views/a-applet/shop_manage/logistics/OrderLogisticsList'
export default {
  name: 'TableList',
  components: {
    OrderLogisticsTrajectoryForm,
    STable,
    OrderCirculateConfig,
    OrderLogisticsImportForm,
    OrderStatusForm,
    LogisticsForm,
    OrderLogisticsList
  },
  data () {
    return {
      import_form_visible: false,
      importConfirmLoading: false,
      title: '自动转到仓库订单',
      pageNum: 1,
      select_status_visible: false,
      confirm_loading: false,
      refund_status_visible: false,
      select_status_data: '3',
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      order_id: 0,
      // 创建窗口控制
      visible: false,
      configVisible: false,
      statusVisible: false,
      logisticsVisible: false,
      logisticsListVisible: false,
      statusConfirmLoading: false,
      logisticsConfirmLoading: false,
      logisticslistLoading: false,
      logisticsTrajectoryVisible: false,
      mdl: {},
      // 查询参数
      queryParam: {
        is_have_child: false
      },
      selectedRowKeys: [],
      selectedRows: [],
      orderList: [],
      orderIdsList: [],
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          getCheckboxProps: record => ({
            props: {
              disabled: (record.status !== 2) || (record.is_sync)
              // disabled: (record.status !== 2) || (record.is_sync || record.supplier_is_open_api || record.is_pushed)
            }
          }),
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单编号',
          ellipsis: true,
          dataIndex: 'order_no',
          fixed: 'left',
          width: 200
        },
        {
          title: '收货人',
          width: 120,
          dataIndex: 'receiving_name'
        },
        {
          title: '电话',
          ellipsis: true,
          width: 150,
          dataIndex: 'receiving_phone'
        },
        {
          title: '支付金额',
          dataIndex: 'actual_paid',
          align: 'right',
          width: 150,
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          align: 'center',
          width: 150,
          customRender: (text) => this.$Dictionaries.applet_order_status[text] || '无'
        },
        {
          title: '供应商',
          width: 250,
          align: 'left',
          ellipsis: true,
          dataIndex: 'supplier.name'
        },
        {
          title: '是否已推送',
          width: 150,
          align: 'center',
          dataIndex: 'is_pushed',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '下单时间',
          width: 200,
          align: 'center',
          dataIndex: 'create_time',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text
        },
        {
          title: '支付时间',
          dataIndex: 'pay_time',
          width: 200,
          align: 'center',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            const entries = res.data.entries.filter(item => {
              return this.orderIdsList.indexOf(item.id) < 0
            })
            if (entries.length > 0) {
              this.orderList.push(...entries)
              this.orderIdsList.push(...entries.map(item => item.id))
            }
            return res.data
          })
      }
    }
  },
  created () {
    // this.tableOption()
    this.setCurrentPage()
  },
  computed: {
    showMore () {
      return (record) => {
        return this.showPushOrder(record) || this.showChangeStatus(record)
      }
    },
    rowSelection () {
      const that = this
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          that.onSelectChange(selectedRowKeys, selectedRows)
        },
        selectedRowKeys: this.selectedRowKeys,
        getCheckboxProps: record => ({
          props: {
            disabled: (record.status !== 2) || (record.is_sync)
          }
        })
      }
    },
    hasSelected () {
      return this.selectedRowKeys.length > 0
    },
    showLogistics () {
      return (record) => record.is_third_supplier && (record.status === 2 || record.status === 3 || record.status === 4)
    },
    showPushOrder () {
      return (record) => record.supplier_is_open_api && !record.is_pushed && !record.is_have_child && record.status === 2
    },
    showChangeStatus () {
      return (record) => {
        const firstFilter = (record.status <= 3 && record.status > 1)
        return firstFilter
      }
    },
    showChangeLogistics () {
      return (record) => {
        return record.is_third_supplier && (record.status === 2 || record.status === 3)
      }
    }
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            getCheckboxProps: record => ({
              props: {
                disabled: (record.status !== 2) || (record.is_sync)
              }
            }),
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleDetail (record) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: 'order/detail', query: { id: record.id, status: record.status, supplier: record.supplier.name } })
    },
    handleLogistics (record) {
      this.mdl = record
      this.logisticsListVisible = true
    },
    handleLogisticsTrajectory (record) {
      this.mdl = record
      this.logisticsTrajectoryVisible = true
    },
  //  手动补订单库存数量
    onDeliverFirstChange (e) {
      if (!e.target.checked) {
        this.queryParam.distribution = undefined
      } else {
        this.queryParam.distribution = 2
      }
      this.$refs.table.refresh(true)
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data,
        order_id: this.order_id
      }
      if (this.select_status_data === '5') {
        param.refund = this.refund
      }
      this.confirm_loading = true
      orderToExamine(param).then(({ data }) => {
        this.select_status_data = '请选择'
        this.refund = 0
        this.order_id = 0
        this.select_status_visible = false
        this.confirm_loading = false
        this.$refs.table.refresh(true)
      })
    },
    select_refund () {
      if (this.refund === 0) {
        this.$message.warning('请输入退款金额')
        return
      }
      const param = {
        refund: Math.round(this.refund * 100),
        reason: this.refund_reason,
        order_id: this.order_id
      }

      doRefund(param).then(({ data }) => {
        this.refund = 0
        this.order_id = 0
        this.reason = ''
        this.refund_status_visible = false
        this.$refs.table.refresh(true)
      })
    },
    isRefund (status) {
      return status === 11 || status === 2 || status === 3
    },
    // 订单审核
    handle_to_warehouse () {
      if (this.selectedRows && this.selectedRows.length > 0) {
        const filterRows = this.selectedRows.filter(item => {
          return item.status === 2
        })
        if (filterRows.length !== this.selectedRows.length) {
          this.$message.warning('选择已支付未发货订单')
        } else {
          const data = {
            order_ids: this.selectedRowKeys,
            supplier_ids: this.selectedRows.map(item => item.supplier.id)
          }
          orderToWarehouse(data).then(res => {
            if (res.code === 1000) {
              this.$message.warning('操作成功')
              this.selectedRowKeys = []
              this.selectedRows = []
              this.$refs.table.refresh(true)
            }
          })
        }
      } else {
        this.$message.warning('选择订单')
      }
    },
    // 导出订单订单
    handleExportOrder () {
      const selectedOrderList = this.orderList.filter(item => this.selectedRowKeys.indexOf(item.id) > -1)
      if (selectedOrderList && selectedOrderList.length > 0) {
        const filterRows = selectedOrderList.filter(item => {
          return item.status === 2
        })
        if (filterRows.length !== selectedOrderList.length) {
          this.$message.warning('选择待发货订单')
        } else {
          if (filterRows.length > 1000) {
            this.$message.warning('选择订单数量过多')
            return
          }
          const items = filterRows.map(item => {
            return item.id + '@@' + item.supplier.id
          })
          const data = {
            orders: items.join(',')
          }
          wechatOrderExport(data).then(res => {
            const link = document.createElement('a')
            const blob = new Blob([res], { type: 'application/octet-stream' })
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            link.setAttribute('download', `订单.xlsx`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.selectedRowKeys = []
            this.selectedRows = []
          })
        }
      } else {
        this.$message.warning('选择订单')
      }
    },
    // 订单退款
    handleRefund (record) {
      // this.refund_status_visible = true
      // // 2 审核通过，5 审核不通过
      // this.order_id = record.id
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: 'order/detail_refund', query: { id: record.id } })
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: 'order/detail_refund', query: { id: record.id, status: record.status } })
    },
    handleSelectChange (value) {
      this.select_status_data = value
    },
    handleReceived (value) {
      putOrderStatus(undefined, value.id).then(data => {
        if (data) {
            if (data.code === 1000) {
              this.$refs.table.refresh(true)
            } else {
              if (data.msg) {
                this.$message.warning(data.msg)
              }
            }
        }
      })
    },
    handlePushOrder (record) {
      const data = {
        order_id: record.id,
        supplier_id: record.supplier.id
      }
      orderToSupplier({ order: data }).then(res => {
        if (res.code === 1000) {
          this.$message.warning('操作成功')
          this.$refs.table.refresh(true)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    handleAgreeRefund (value) {
      putReceived({ order_no: value.order_no }).then(data => {
        if (data) {
            if (data.code === 1000) {
              this.$refs.table.refresh(true)
            } else {
              if (data.msg) {
                this.$message.warning(data.msg)
              }
            }
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleConfigCancel () {
      this.configVisible = false
      this.confirmLoading = false
    },
    handleConfigOk () {
      const form = this.$refs.configModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          putOrderToWarehouseConfig(values, this.mdl.id).then(res => {
            if (res.code === 1000) {
              this.handleConfigCancel()
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    clear () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    handleImport () {
      this.import_form_visible = true
    },
    handleImportCancel () {
      this.import_form_visible = false
      const form = this.$refs.orderLogisticsImportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleImportOk (fileList) {
      const form = this.$refs.orderLogisticsImportModal.form
      this.importConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          const formData = new FormData()
          formData.append('file', fileList[0])
          orderLogisticsImport(formData).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.import_form_visible = false
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.importConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.importConfirmLoading = false
        }
      })
    },
    handleChangeStatus (record) {
      this.mdl = record
      this.statusVisible = true
    },
    handleStatusCancel () {
      this.statusVisible = false
    },
    handleStatusOk () {
      const form = this.$refs.orderStatusModal.form
      this.statusConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          putOrderStatus(values['status'], this.mdl.id).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.statusVisible = false
            this.statusConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.statusConfirmLoading = false
        }
      })
    },
    addLogistics (record) {
      this.handleChangeLogistics(record)
    },
    handleChangeLogistics (record) {
      this.mdl = record
      this.logisticsVisible = true
    },
    handleLogisticsCancel () {
      this.logisticsVisible = false
    },
    handleLogisticsOk () {
      const form = this.$refs.logisticsModal.form
      this.logisticsConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.order_id = this.mdl.id
          putOrderLogistics(values).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.logisticsVisible = false
            this.logisticsConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.logisticsConfirmLoading = false
        }
      })
    },
    handleLogisticsListCancel () {
      this.logisticsListVisible = false
    },
    handleLogisticsListOK () {
      const form = this.$refs.logisticsListModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          this.logisticslistLoading = true
          values.order_id = this.mdl.id
          putOrderLogistics(values).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.logisticsListVisible = false
            this.logisticslistLoading = false
            form.resetFields()
          })
        } else {
          this.logisticslistLoading = false
        }
      })
    }
  }
}
</script>
