<template>
  <a-drawer
    :title="'订单【' + model.order_no + '】关联物流'"
    placement="right"
    :visible="visible"
    width="1080"
    :closable="true"
    @close="() => { $emit('cancel') }"
  >
    <div class="table-page-search-wrapper" style="margin-bottom: 20px" v-if="model.status === 2 || model.status === 3">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="2" :sm="24">
            <a-button type="primary" icon="plus" @click="handleAdd">添加物流</a-button>
          </a-col>
        </a-row>
        <a-row :gutter="48" style="margin-top: 10px" v-if="model.status === 3">
          <a-col>
            <span style="color: red">提示：“已发货”订单补录物流信息，会影响订单的确认时间</span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="price_render" slot-scope="text" style="text-align: left; min-width: 90px; max-width: 240px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <template slot="action" slot-scope="text, record">
        <a @click="handleLogisticsTrajectory(record)">物流轨迹</a>
        <a-divider type="vertical" v-if="record.status === 1"/>
<!--        <a v-if="record.status === 1" @click="handleChangeLogistics(record)">修改物流</a>-->
<!--        <a-divider type="vertical" v-if="record.status === 1"/>-->
        <a-popconfirm
          title="确定要删除么？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="handleDelete(record)"
        >
          <a v-if="record.status === 1">删除物流</a>
        </a-popconfirm>
      </template>
    </s-table>
    <logistics-form
      ref="logisticsModal"
      v-if="logisticsVisible"
      :visible.sync="logisticsVisible"
      :loading="logisticsConfirmLoading"
      @cancel="handleLogisticsCancel"
      @ok="handleLogisticsOk"
    />
    <order-logistics-trajectory-form
      ref="orderLogisticsTrajectoryModal"
      v-if="logisticsTrajectoryVisible"
      :visible.sync="logisticsTrajectoryVisible"
      :loading="logisticsTrajectoryLoading"
      :model="mdl"
      @cancel="handleCancelLogisticsTrajectory"
    />
  </a-drawer>
</template>
<script>

import {
  addOrderLogistics,
  deleteOrderLogistics,
  editOrderLogistics,
  orderLogisticsList
} from '@/api/applet_shopmall_order'
import { STable } from '@/components'
import LogisticsForm from '@/views/a-applet/shop_manage/logistics/LogisticsForm'
import OrderLogisticsTrajectoryForm from '@/views/a-applet/shop_manage/logistics/OrderLogisticsTrajectoryForm'
export default {
  name: 'ValueCardConsumeRecordList',
  components: {
    STable,
    LogisticsForm,
    OrderLogisticsTrajectoryForm
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '物流名称',
          ellipsis: true,
          dataIndex: 'logistics'
        },
        {
          title: '单号',
          ellipsis: true,
          dataIndex: 'waybill_no'
        },
        {
          title: '状态',
          ellipsis: true,
          align: 'center',
          width: '80px',
          dataIndex: 'status',
          customRender: (text) => text === 1 ? '运输中' : '已完成'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: '280px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      logisticsVisible: false,
      logisticsTrajectoryVisible: false,
      logisticsConfirmLoading: false,
      logisticsTrajectoryLoading: false,
      loading: true,
      pageNum: 1,
      mdl: undefined,
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderLogisticsList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            return res.data
          })
      }
    }
  },
  created () {
    this.queryParam.order_id = this.model.id
    console.log('查看接到的订单物流相关', this.model)
  },
  methods: {
    handleAdd () {
      console.log('添加物流')
      this.logisticsVisible = true
      // this.$emit('addLogistics', this.model)
    },
    handleLogisticsCancel () {
      this.logisticsVisible = false
      if (this.mdl !== undefined) {
        this.mdl = undefined
      }
    },
    handleLogisticsOk () {
      const form = this.$refs.logisticsModal.form
      this.logisticsConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.order_id = this.model.id
          if (this.mdl === undefined) {
            addOrderLogistics(values).then(res => {
              // 刷新表格
              if (res.code === 1000) {
                this.logisticsVisible = false
                this.$refs.table.refresh()
              }
            }).finally(() => {
              this.logisticsConfirmLoading = false
              if (!this.logisticsVisible) {
                form.resetFields()
              }
            })
          } else {
            editOrderLogistics(this.mdl.id, values).then(res => {
              if (res.code === 1000) {
                this.logisticsVisible = false
                this.mdl = undefined
                this.$refs.table.refresh()
              }
            }).finally(() => {
              this.logisticsConfirmLoading = false
              if (!this.logisticsVisible) {
                form.resetFields()
              }
            })
          }
        } else {
          this.logisticsConfirmLoading = false
        }
      })
    },
    handleLogisticsTrajectory (record) {
      this.mdl = {
        order_no: this.model.order_no,
        waybill_no: record.waybill_no,
        logistics_abbreviation: record.logistics_abbreviation
      }
      this.logisticsTrajectoryVisible = true
    },
    handleCancelLogisticsTrajectory () {
      this.mdl = undefined
      this.logisticsTrajectoryVisible = false
    },
    handleChangeLogistics (record) {
      this.mdl = record
      this.logisticsVisible = true
    },
    handleDelete (record) {
      deleteOrderLogistics(record.id).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>
<style>
</style>
