<template>
  <a-modal
    title="订单物流轨迹"
    :width="680"
    :visible="visible"
    :footer="null"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading" tip="加载中...">
      <a-timeline v-if="data" style="height: 500px; overflow-y: scroll">
        <a-timeline-item :color="index=== 0 ? 'red': 'green'" v-for="(item, index) in data.list" :key="item.item">
          <div>
            <p>{{ item.time }}</p>
            <p>{{ item.status }}</p>
          </div>
        </a-timeline-item>
      </a-timeline>
      <a-result v-else title="暂无数据" sub-title="暂时查不到订单的物流轨迹，请稍后再试">
      </a-result>
    </a-spin>
  </a-modal>
</template>
<script>
import { orderLogisticsTrajectory } from '@/api/applet_shopmall_order'

export default {
  name: 'OrderLogisticsTrajectoryForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      data: {},
      loading: false
    }
  },
  created () {
    this.loadOrderLogisticsTrajectory()
  },
  filters: {
    deliveryStatus (status) {
      /* 0：快递收件(揽件)1.在途中 2.正在派件 3.已签收 4.派送失败 5.疑难件 6.退件签收  */
      let msg = ''
      switch (status) {
        case 0:
          msg = '揽件'
          break
        case 1:
          msg = '运输中'
          break
        case 2:
          msg = '正在派件'
          break
        case 3:
          msg = '已签收'
          break
        case 4:
          msg = '派送失败'
          break
        case 5:
          msg = '疑难件'
          break
        case 6:
          msg = '退件签收'
          break
      }
      return msg
    }
  },
  methods: {
    loadOrderLogisticsTrajectory () {
      this.loading = true
      orderLogisticsTrajectory({ order_no: this.model.order_no, waybill_no: this.model.waybill_no, logistics_abbreviation: this.model.logistics_abbreviation }).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.data = res.data
        } else {
          this.data = undefined
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
